import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import {
  createSlug,
  clearHtmlAndshrink,
  displayCountryName,
  contentQuickPost,
} from "../components/utils"
import { css } from "@emotion/core"
import { colors, emojiBounce } from "../components/styles"

const CardNews = ({ news, displayDate, displayCountry, displaySneakPeek }) => {
  const slug = createSlug(news.type.name, news.createdAt, news.title)
  // const content = clearHtmlAndshrink(news.content, displaySneakPeek)

  return (
    <div className="card" css={cardNews}>
      <Link to={slug}>
        {news.image && (
          <Img
            className="card-image"
            fluid={news.image.childImageSharp.fluid}
          />
        )}
        {displayCountry && ( // display country tags
          <div className="field is-grouped cardTag">
            <div className="control">
              <div className="tags has-addons">
                <span className="tag is-dark">{news.type.name}</span>
                {news.countries.length && (
                  <span className={`tag is-${news.countries[0].code}`}>
                    {displayCountryName(news.countries[0].code)}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        {displayDate && (
          <time dateTime={news.createdAt} className="is-size-7">
            {displayDate && news.createdAtReadable.slice(0, -7)}
          </time>
        )}
        {!displaySneakPeek && ( // no sneak peek: Title on top
          <h5 className="title is-6 is-size-6-touch titleOnTop">
            <span>{news.bait ? news.bait : news.title}</span>
          </h5>
        )}
      </Link>

      {displaySneakPeek && (
        <Link to={slug}>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                {news.emoji && (
                  <aside className="emoji">
                    <span>{news.emoji}</span>
                  </aside>
                )}

                <p className="title is-5">
                  {news.bait ? news.bait : news.title}{" "}
                </p>
              </div>
            </div>
            {news.content && (
              <div className="content">
                <p>
                  <span
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{
                      __html: clearHtmlAndshrink(
                        contentQuickPost(
                          news.quick_post,
                          news.music_title,
                          news.content ? news.content : ""
                        ),
                        displaySneakPeek
                      ),
                    }}
                  />
                </p>
              </div>
            )}
          </div>
        </Link>
      )}
    </div>
  )
}

export default CardNews

const cardNews = css`
  &.card {
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.01) rotate(1deg);
      .emoji {
        animation: ${emojiBounce} 1s infinite;
      }
    }
  }

  .card-content {
    position: relative;
    .title {
      color: ${colors.primary};
    }
    .content {
      p {
        color: ${colors.text};
      }
    }
  }
  .emoji {
    background: white;
    width: 60px;
    height: 60px;
    position: absolute;
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.6rem;
    border: solid 1px #eee;
    transition: transform 0.3s ease-in-out;
  }
  .card-image {
    height: 270px;
    img {
      /* max-height: 200px; */
      width: auto;
      margin: auto;
    }
  }
  .cardTag {
    margin: 20px;
    position: absolute;
    top: 0;
    .tag {
      font-weight: bold;
      &.is-bra {
        color: #fff;
        background: rgb(254, 223, 0);
        background: radial-gradient(
          circle at bottom,
          rgba(200, 175, 0, 1) 0%,
          rgba(0, 155, 58, 1) 100%
        );
      }
    }
  }
  time {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 8px;
    margin-top: 20px;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .titleOnTop {
    position: absolute;
    padding: 10px;

    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    bottom: 0px;
  }
`
