import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CardNews from "../components/cardNews"
import CardFeatured from "../components/cardFeatured"


const countriesTemplate = ({ data, pageContext }) => {
  // console.log(pageContext.countryCode)
  let titleSection = ""
  let urlName = ""
  let firstFeaturedHasBeenRemoved = false

  switch (pageContext.countryCode.toLowerCase()) {
    case "fra":
      titleSection = data.globalYaml.index.title_fra
      urlName = "france"
      break
    case "usa":
      titleSection = data.globalYaml.index.title_usa
      urlName = "united-states"
      break
    case "lat":
      titleSection = data.globalYaml.index.title_lat
      urlName = "latin-america"
      break
    case "bra":
      titleSection = data.globalYaml.index.title_bra
      urlName = "brasil"
      break

    default:
      break
  }

  return (
    <Layout>
      <SEO title={`News`} url={`/${urlName}/`} />
      <div className="container">
        {data.lastArticlesFeatured.nodes.map((articleFeatured, i) => {
          return <CardFeatured key={i} news={articleFeatured} />
        })}
      </div>
      <div className="columns is-centered is-marginless">
        <div className="column is-8">
          <div className="container">
            <h1 className="title is-4">{titleSection}</h1>
            <div className="columns is-multiline is-centered">
              {data.allStrapiPostsByCountry.edges.map((post, i) => {
                // hide first featured post (because its already displayed in cardFeature)

                if (firstFeaturedHasBeenRemoved === false) {
                  if (post.node.featured === true) {
                    firstFeaturedHasBeenRemoved = true
                    return false
                  }
                }
                return (
                  <div key={i} className="column is-6">
                    <CardNews
                      news={post.node}
                      displayCountry={false}
                      displayDate={true}
                      displaySneakPeek={40} // false or number of char to display
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default countriesTemplate

export const queryNewsTemplate = graphql`
  query($countryCode: String!) {
    allStrapiPostsByCountry: allStrapiPosts(
      filter: { countries: { elemMatch: { code: { eq: $countryCode } } } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          bait
          title
          emoji
          featured
          content
          quick_post
          music_title
          album_title
          producer
          director_realisateur
          createdAt
          createdAtReadable: createdAt(
            formatString: "DD MMMM YYYY à HH:mm"
            locale: "fr_FR"
          )
          type {
            name
          }
          countries {
            code
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    lastArticlesFeatured: allStrapiPosts(
      filter: {
        featured: { eq: true }
        countries: { elemMatch: { code: { eq: $countryCode } } }
      }
      limit: 1
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...StrapiPosts
      }
    }
    globalYaml: globalYaml {
      index {
        title
        title_fra
        title_usa
        title_lat
        title_bra
      }
    }
  }
`
