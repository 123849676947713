export function createSlug(category, datePost, title) {
  // creating a slug format :  /category/year-month-day/title

  return (
    `/${category}/` +
    datePost.slice(0, 10) +
    `/` +
    title
      .replace(/[éèëê]/g, "e")
      .replace(/[àãäâ]/g, "a")
      .replace(/[òöõô]/g, "o")
      .replace(/[ñ]/g, "n")
      .replace(/[-_]/g, "")
      .replace(/\s\s+/g, " ") // double spaces
      .replace(/\W/g, "-")
  )
}

export function clearHtmlAndshrink(content = "", shrink = 80) {
  return (
    content
      .replace(/(<([^>]+)>)/gi, "")
      .replace(/\((.*?)\)|[[*>]|[\]]/g, "") // remove markdown template characters
      .substring(0, shrink) + "..."
  )
}

export function displayCountryName(code) {
  let countryName = ""
  switch (code) {
    case "bra":
      countryName = "Brésil"
      break
    case "usa":
      countryName = "États-Unis"
      break
    case "fra":
      countryName = "France"
      break
    case "lat":
      countryName = "Latin"
      break
    default:
      countryName = ""
      break
  }
  return countryName
}

export function contentQuickPost(quickPost, musicTitle, content) {
  return quickPost
    ? `Le clip de ${musicTitle} est maintenant disponible`
    : content
}
