import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {
  createSlug,
  displayCountryName,
} from "../components/utils"
import { css } from "@emotion/core"
import { colors, mq } from "../components/styles"

function duplicateEmoji(emoji = "💯", times) {
  let duplicated = ""
  for (let i = 0; i < times; i++) {
    duplicated += emoji
  }
  return duplicated
}

const CardFeatured = ({ news }) => {
  const slug = createSlug(news.type.name, news.createdAt, news.title)
  const emojiPattern = duplicateEmoji(news.emoji, 30)

  return (
    <article className="featured" css={cardFeaturedCss}>
      <div className="emojiBackground">
        {news.emoji && <p>{emojiPattern}</p>}
      </div>
      <Link to={slug}>
        <div className="featuredContent">
          <div className="featuredImage">
            <Img
              className="card-image"
              fluid={news.image.childImageSharp.fluid}
            />
          </div>
          <div className="featuredTitle">
            {/* display country only for bra and lat */}
            {news.countries[0].code !== "fra" &&
              news.countries[0].code !== "usa" && (
                <div className="featuredTag">
                  <span className="featuredColorTag"></span>
                  {news.countries.length && (
                    <span className="featuredTagName">
                      {displayCountryName(news.countries[0].code)}
                    </span>
                  )}
                </div>
              )}
            <h2 className="title">{news.bait ? news.bait : news.title} </h2>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default CardFeatured


const cardFeaturedCss = css`
  &.featured {
    margin-bottom: 60px;
    ${mq[1]} {
      max-width: 1000px;
      margin: auto;
    }
    .emojiBackground {
      font-size: 2rem;
      opacity: 0.5;
      position: absolute;
      margin: 0 -20px;
      /* max-height: 400px; */
      max-width: 100%;
      overflow: hidden;
      transition: transform 0.3s ease-in-out;
      ${mq[1]} {
        z-index: 1;
        opacity: 1;
        margin: 0;
        max-width: 30%;
        right: 10%;
        max-height: 260px;
        top: 30%;
      }
      p {
        text-align: center;
        letter-spacing: 1.5rem;
        line-height: 4rem;
        transform: rotate(-5deg);
        padding-bottom: 10px;
      }
    }
    .featuredContent {
      padding: 10px;
      padding-top: 40px;
    }
    .featuredImage {
      opacity: 0.9;
      padding: 20px;
      ${mq[1]} {
        opacity: 1;
      }
      .card-image {
        height: 270px;
        ${mq[1]} {
          height: 400px;
          max-width: 80%;
        }
        img {
          /* max-height: 200px; */
          width: auto;
          margin: auto;
        }
      }
    }
    .featuredTitle {
      position: absolute;
      padding: 0 10px;
      background: rgba(255, 255, 255, 0.9);
      margin: 50px;
      margin-top: -60px;
      padding: 10px;
      padding-top: 20px;
      transition: transform 0.2s ease-in-out;
      .title {
        font-size: 1.25rem;
      }

      ${mq[1]} {
        max-width: 30%;
        right: 10%;
        top: 40%;
        margin: 0;
        z-index: 2;
        padding: 20px 10px;
        .title {
          font-family: "Volkom";
          font-size: 2.5rem;
        }
      }
    }
    .featuredTag {
      display: flex;
      position: absolute;
      margin: -35px 0 0 -20px;
      .featuredColorTag {
        width: 25px;
        background: ${colors.primary};
      }
      .featuredTagName {
        padding: 1px 5px 0 5px;
        font-size: 1rem;
        background: #eee;
      }
    }
    &:hover {
      .emojiBackground {
        ${mq[1]} {
          transform: rotate(10deg) scale(1.1);
        }
      }
      .featuredTitle {
        ${mq[1]} {
          transform: translateX(-20px) translateY(-10px) scale(1.2);
        }
      }
    }
  }
`
